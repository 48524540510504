/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-20",
    versionChannel: "nightly",
    buildDate: "2024-10-20T00:06:42.325Z",
    commitHash: "e353fdc1c3e47939807e1d9b109050ed68ca5d62",
};
